/* eslint-disable arrow-parens */
import ApiConf from '../../utils/ApiConf';
import history from '../../utils/history';
// import axios from axios;

// const url = process.env.REACT_APP_API_URL;
const client = process.env.REACT_APP_CLIENT_KEY;
const grant = process.env.REACT_APP_GRANT_TYPE;

const LoginService = async input => {
  let data = '';
  await ApiConf.generalInstance
    .post(
      '/oauth/token',
      {},
      {
        params: {
          username: input.email,
          password: input.password,
          grant_type: grant
        },
        headers: {
          authorization: client
        }
      }
    )
    .then(resp => {
      data = resp.data;
      localStorage.setItem('accessKey', data.access_token);
      localStorage.setItem('refreshKey', data.refresh_token);
      return data;
    });
};

const LogoutService = () => {
  localStorage.clear();
  history.push('/zhs/web/login');
};

const authenticated = () => {
  if (localStorage.getItem('accessKey') !== null) {
    return true;
  }
  return false;
};

const isAdmin = () => {
  const interval = setInterval(() => {
    if (
      localStorage.getItem('role') !== null ||
      localStorage.getItem('role') !== undefined
    ) {
      clearInterval(interval);
    }
  }, 50);

  if (localStorage.getItem('role') === 'ROLE_ADMIN') {
    return true;
  }
  return false;
};

const isClient = () => {
  const interval = setInterval(() => {
    if (
      localStorage.getItem('role') !== null ||
      localStorage.getItem('role') !== undefined
    ) {
      clearInterval(interval);
    }
  }, 50);

  if (
    localStorage.getItem('role') === 'ROLE_ORG_ADMIN' ||
    localStorage.getItem('role') === 'ROLE_ORG_USER'
  ) {
    return true;
  }
  return false;
};

const isManagement = () => {
  const interval = setInterval(() => {
    if (
      localStorage.getItem('role') !== null ||
      localStorage.getItem('role') !== undefined
    ) {
      clearInterval(interval);
    }
  }, 50);

  if (
    localStorage.getItem('role') === 'ROLE_ADMIN' ||
    localStorage.getItem('role') === 'ROLE_STAFF'
  ) {
    return true;
  }
  return false;
};

const staff = () => {
  if (localStorage.getItem('role' === 'ROLE_MANAGEMENT')) {
    return true;
  }
  return false;
};

const roleLoaded = () => {
  if (localStorage.getItem('role') !== null) {
    return true;
  }
  return false;
};

export {
  LoginService,
  LogoutService,
  authenticated,
  isAdmin,
  isManagement,
  staff,
  roleLoaded,
  isClient
};
