import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../../services/AuthService';

const Authenticated = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = AuthService.authenticated();
        const management = AuthService.isManagement();
        console.log(authenticated);
        if (authenticated === true && management === true) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/zhs/web/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const AuthenticatedClient = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = AuthService.authenticated();
        const client = AuthService.isClient();
        if (authenticated === true && client === true) {
          return <Component {...props} />;
        }
        AuthService.LogoutService();
        return (
          <Redirect
            to={{ pathname: '/zhs/web/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = AuthService.authenticated();
        const admin = AuthService.isAdmin();
        const management = AuthService.isManagement();
        if (authenticated === true && (admin === true || management === true)) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/zhs/web/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const SystemAdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = AuthService.authenticated();
        const admin = AuthService.isAdmin();
        if (authenticated === true && admin === true) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/zhs/web/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = AuthService.authenticated();
        if (authenticated !== true) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/zhs/web/dashboard',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export {
  LoginRoute,
  Authenticated,
  AdminRoute,
  SystemAdminRoute,
  AuthenticatedClient
};
