import axios from 'axios';

const generalInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

const authenticatedInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

export { generalInstance, authenticatedInstance };
