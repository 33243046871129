import React, { Suspense, lazy, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/core';
import theme from 'src/theme';
import GlobalStyles from 'src/components/GlobalStyles';
import { withSnackbar } from 'notistack';
import Loading from 'react-loading-components';

import DashboardLayout from 'src/layouts/DashboardLayout';
import ClientDashboardLayout from 'src/layouts/ClientDashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProtectedRoutes from './utils/ProtectedRoutes';
import ApiConf from './utils/ApiConf';
import AuthService from './services/AuthService';

const LoginPage = lazy(() => import('src/views/auth/LoginView'));
const DashboardView = lazy(() => import('src/views/dashboard/DashboardView'));
const ClientDashboardView = lazy(() =>
  import('src/views/dashboard/ClientDashboardView')
);
const ProfileView = lazy(() => import('src/views/profile/ProfileView'));
const AccountView = lazy(() => import('src/views/account/AccountView'));
const TaskView = lazy(() => import('src/views/Tasks'));
const ClientView = lazy(() => import('src/views/clients'));
const ClientSettingsVIew = lazy(() =>
  import('src/views/client-settings/ClientSettingsView')
);
const ShiftDetailsView = lazy(() => import('src/views/ShiftDetails'));
const AccountInfoView = lazy(() => import('src/views/AccountInfoView'));
const ClientShiftsView = lazy(() => import('src/views/ClientShiftsView'));
const ClientProfileView = lazy(() => import('src/views/ClientProfileView'));
const ClientAccountBillings = lazy(() =>
  import('src/views/ClientAccountBillings')
);
const ReportView = lazy(() => import('src/views/reports'));
const AccountDetails = lazy(() => import('src/views/AccountDetails'));

const Routes = props => {
  const location = useLocation();
  // const [notify, setNotify] = useState(false);
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  ApiConf.authenticatedInstance.interceptors.response.use(
    resp => {
      return resp;
    },
    error => {
      if (error.message === 'Request failed with status code 401') {
        AuthService.LogoutService();
        props.enqueueSnackbar('Session Terminated, Login Again!!', {
          variant: 'error',
          preventDuplicate: true
        });
      }
      return Promise.reject(error);
    }
  );
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AnimatePresence />
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">
              <Loading type="puff" fill="#1167b1" />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="zhs/web/login" />
          </Route>
          <Route exact path="/zhs">
            <Redirect to="zhs/web/login" />
          </Route>

          <Route exact path={['/zhs/web/login']}>
            <MainLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route exact path="/zhs/web/login" component={LoginPage} />
                </motion.div>
              </Switch>
            </MainLayout>
          </Route>
          <Route path={['/zhs/web/cust']}>
            <ClientDashboardLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <ProtectedRoutes.AuthenticatedClient
                    exact
                    path="/zhs/web/cust"
                    component={ClientDashboardView}
                  />
                  <ProtectedRoutes.AuthenticatedClient
                    exact
                    path="/zhs/web/cust/shifts"
                    component={ClientShiftsView}
                  />
                  <ProtectedRoutes.AuthenticatedClient
                    exact
                    path="/zhs/web/cust/profile"
                    component={ClientProfileView}
                  />
                  <ProtectedRoutes.AuthenticatedClient
                    exact
                    path="/zhs/web/cust/billing"
                    component={ClientAccountBillings}
                  />
                </motion.div>
              </Switch>
            </ClientDashboardLayout>
          </Route>
          <Route
            path={[
              '/zhs/web',
              '/zhs/web/profile',
              '/zhs/web/accounts',
              '/zhs/web/accounts/id',
              '/zhs/web/tasks',
              '/zhs/web/clients',
              '/zhs/web/clients/:id',
              '/zhs/web/report',
              '/zhs/web/tasks/:id'
            ]}
          >
            <DashboardLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <ProtectedRoutes.Authenticated
                    exact
                    path="/zhs/web"
                    component={DashboardView}
                  />
                  <ProtectedRoutes.Authenticated
                    exact
                    path="/zhs/web/tasks"
                    component={TaskView}
                  />
                  <ProtectedRoutes.Authenticated
                    exact
                    path="/zhs/web/profile"
                    component={ProfileView}
                  />
                  <ProtectedRoutes.AdminRoute
                    exact
                    path="/zhs/web/accounts"
                    component={AccountView}
                  />
                  <ProtectedRoutes.AdminRoute
                    exact
                    path="/zhs/web/clients"
                    component={ClientView}
                  />
                  <ProtectedRoutes.AdminRoute
                    exact
                    path="/zhs/web/clients/:id"
                    component={ClientSettingsVIew}
                  />
                  <ProtectedRoutes.Authenticated
                    exact
                    path="/zhs/web/tasks/:id"
                    component={ShiftDetailsView}
                  />
                  <ProtectedRoutes.AdminRoute
                    exact
                    path="/zhs/web/report"
                    component={ReportView}
                  />
                  <ProtectedRoutes.AdminRoute
                    exact
                    path="/zhs/web/accounts/:id"
                    component={AccountDetails}
                  />
                </motion.div>
              </Switch>
            </DashboardLayout>
          </Route>

          <Route exact path="*" component={NotFoundView} />
        </Switch>
      </Suspense>
    </ThemeProvider>
  );
};

export default withSnackbar(Routes);
