import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Component } from 'react';
import { Router } from 'react-router';
import 'src/mixins/chartjs';
import Routes from './Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';

import ScrollToTop from './utils/ScrollToTop';
import history from './utils/history';
import './assets/base.scss';

class App extends Component {
  render() {
    return (
      <div>
        <SnackbarProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </Router>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </div>
    );
  }
}

export default App;
